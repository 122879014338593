.quantity {
    margin-left: 1rem;
    margin-right: 1rem;
    display: inline;
}

.price {
    margin-bottom: 1rem;
    display: block;
}

.total {
    margin-top: 1rem;
    font-size: 2rem;
    display: block;
}

.alert-danger-custom {
    word-break: break-word;
    color: white;
    background-color: red;
    border-color: red;
}

.alert-success-custom {
    word-break: break-word;
    color: white;
    background-color: #00d458;
    border-color: #00d458;
}


.btn-custom{
    border: 3px solid #07effd !important;
    background-color: transparent !important;
    text-transform: uppercase !important;
    font-weight: bold !important;
    color: #07effd !important;
    border-radius: 0px !important;
    font-family: 'Oswald', sans-serif  !important;
}


.btn-custom:hover{
    border: 3px solid white !important;
    background-color: white !important;
    text-transform: uppercase !important;
    color: black !important;
    border-radius: 0px !important;
}


.btn-custom-2{
    border: 3px solid white !important;
    background-color: transparent !important;
    text-transform: uppercase !important;
    
    padding-top: 14px !important;
    padding-right: 38px !important;
    padding-bottom: 14px !important;
    padding-left: 38px !important;
    margin-left: 18px !important;

    color: white !important;
    border-radius: 0px !important;
    font-family: 'Oswald', sans-serif  !important;
}


.btn-custom-2:hover{
    border: 3px solid white !important;
    background-color: white !important;
    text-transform: uppercase !important;
    color: #07effd !important;
    border-radius: 0px !important;
}