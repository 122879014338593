@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.overlay{
  background-color: rgba(0, 0, 0, 0.3);
  min-height: 97vh;
  position: relative;
}

.logo{
  max-height: 2rem;
}

.main-title{
  color: white;
  margin-top: 1rem;
  font-size: 3rem;
  text-shadow: rgba(0, 0, 0, 0.6);
  font-family: 'Oswald', sans-serif  !important;
}

body{
  min-height: 100vh;
  background-image: url("../src/assets/img/header_bg.jpg");
  color: #D8D8d8 !important;
  font-size: 1rem;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Roboto', sans-serif  !important;

}