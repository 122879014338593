@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100&display=swap');

footer{
 background-color: #030303;
}

.logo-footer{
    max-width: 179px;
}

.powered-by{
    font-family: 'Roboto', sans-serif;
    font-size: 0.8rem;
}

.powered-by a {
    color: white;
}

.signor-crypto{
    max-height: 25px;
}