@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}





.overlay{
  background-color: rgba(0, 0, 0, 0.3);
  min-height: 97vh;
  position: relative;
}

.logo{
  max-height: 2rem;
}

.main-title{
  color: white;
  margin-top: 1rem;
  font-size: 3rem;
  text-shadow: rgba(0, 0, 0, 0.6);
  font-family: 'Oswald', sans-serif  !important;
}

body{
  min-height: 100vh;
  background-image: url(/static/media/header_bg.b436fd66.jpg);
  color: #D8D8d8 !important;
  font-size: 1rem;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Roboto', sans-serif  !important;

}
.quantity {
    margin-left: 1rem;
    margin-right: 1rem;
    display: inline;
}

.price {
    margin-bottom: 1rem;
    display: block;
}

.total {
    margin-top: 1rem;
    font-size: 2rem;
    display: block;
}

.alert-danger-custom {
    word-break: break-word;
    color: white;
    background-color: red;
    border-color: red;
}

.alert-success-custom {
    word-break: break-word;
    color: white;
    background-color: #00d458;
    border-color: #00d458;
}


.btn-custom{
    border: 3px solid #07effd !important;
    background-color: transparent !important;
    text-transform: uppercase !important;
    font-weight: bold !important;
    color: #07effd !important;
    border-radius: 0px !important;
    font-family: 'Oswald', sans-serif  !important;
}


.btn-custom:hover{
    border: 3px solid white !important;
    background-color: white !important;
    text-transform: uppercase !important;
    color: black !important;
    border-radius: 0px !important;
}


.btn-custom-2{
    border: 3px solid white !important;
    background-color: transparent !important;
    text-transform: uppercase !important;
    
    padding-top: 14px !important;
    padding-right: 38px !important;
    padding-bottom: 14px !important;
    padding-left: 38px !important;
    margin-left: 18px !important;

    color: white !important;
    border-radius: 0px !important;
    font-family: 'Oswald', sans-serif  !important;
}


.btn-custom-2:hover{
    border: 3px solid white !important;
    background-color: white !important;
    text-transform: uppercase !important;
    color: #07effd !important;
    border-radius: 0px !important;
}
footer{
 background-color: #030303;
}

.logo-footer{
    max-width: 179px;
}

.powered-by{
    font-family: 'Roboto', sans-serif;
    font-size: 0.8rem;
}

.powered-by a {
    color: white;
}

.signor-crypto{
    max-height: 25px;
}
.nav-link{
  font-family: 'Roboto', sans-serif !important;
}
